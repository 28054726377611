import {
  PartnerBucketGroupPrice,
  PartnerBucketGroups,
} from "./partnerDeal.interface";
import { PlatformType } from "./paxTerminal.interface";

export type IPartner = IPartnerDetails & {
  _id: string;
  purchPriceProfiles: IPurchPriceProfile[];
  createdAt: string;
  teamMembers: IPartnerTeamMember[];
  refCode: string;
  defaultPricing: PartnerBucketGroups;
  softwareVendorPricing: PartnerBucketGroups;
  terminalCouplingPlatform: PlatformType;
  partnerType: PartnerType;
  role: PartnerRole;
  settings?: IPartnerSettings;
  softwareVendorId?: string;
};

export type IPartnerDetails = {
  name: string;
  ownerName: string;
  phone: { number: string; countryCode: string };
  email: string;
};

export type IPurchPriceProfile = {
  _id: string;
  name: string;
  bucketGroups: IBucketGroups;
  whitelist?: string[];
  pricingTemplate?: PricingTemplate;
};
export enum PricingTemplate {
  Default = "default", //domestic debit, european, international and commercial
  EuropeanDebitSplit = "europeanDebitSplit", //domestic debit, european debit, european, international and commercial
}
export type PartnerBucketGroupMinMaxPrice = PartnerBucketGroupPrice & {
  max: number;
};
export interface IBucketGroups {
  domesticDebitCards: PartnerBucketGroupMinMaxPrice;
  europeanDebitCards?: PartnerBucketGroupMinMaxPrice;
  european: PartnerBucketGroupMinMaxPrice;
  internationalAndCommercial: PartnerBucketGroupMinMaxPrice;
}

export type IPartnerTeamMember = {
  name: string;
  email: string;
  _id: string;
  createdAt: string;
};
export interface IPartnerSettings {
  webhooks: {
    merchantVerified: { url: string };
    paymentComplete: { url: string };
  };
}
export enum PartnerType {
  SoftwareVendor = "SoftwareVendor", // Independent Software Vendor (ISV)
  Reseller = "Reseller", // Value-Added Reseller (VAR)
  FullServicePartner = "FullServicePartner", // Full-Service Partner (software vendor + reseller)
  FullServiceWithResellers = "FullServiceWithResellers", // Full-Service Partner with resellers (internal + external resellers)
}
export enum PartnerRole {
  Admin = "Admin", // Admin
  User = "User", // User
}
