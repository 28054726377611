import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Segmented,
  Select,
  Space,
  Tabs,
} from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  createPartnerThunk,
  editPartnerThunk,
  selectCurrentPartner,
} from "../../partnersSlice";
import styles from "./EditPartnerForm.module.css";
import {
  IPartner,
  IPartnerDetails,
  IPurchPriceProfile,
  PartnerType,
  PricingTemplate,
} from "../../../../interfaces/partner.interface";
import PurchPriceProfileForm from "../purch-price-profile-form/PurchPriceProfileForm";
import { DEFAULT_PURCH_PRICE_PROFILE } from "../../utils/defaultPurchPriceProfile";
import {
  EditOutlined,
  PlusCircleOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import PartnerDetailsForm from "../partner-details-form/partnerDetailsForm";
import CreateEditBucketGroupsForm from "../../../partner-deals/components/create-edit-bucket-groups-form/CreateEditBucketGroupsForm";
import { PartnerBucketGroups } from "../../../../interfaces/partnerDeal.interface";
import PartnerTypeForm from "../partner-type-form/partnerTypeForm";

const partnerFormFieldLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 12 },
};
interface IPropsEditPartnerFormModal {
  visible: boolean;
  setVisible: any;
}

let NEW_PURCH_PRICE_PROFILE = { ...DEFAULT_PURCH_PRICE_PROFILE, name: "" };

export default function EditPartnerForm({
  visible,
  setVisible,
}: IPropsEditPartnerFormModal) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const partner = useAppSelector(selectCurrentPartner);
  const [selectedPurchPriceProfile, setSelectedPurchPriceProfile] = useState(
    partner?.purchPriceProfiles?.length > 0
      ? partner?.purchPriceProfiles[0]
      : NEW_PURCH_PRICE_PROFILE
  );

  const [showResults, setShowResults] = useState(false);
  const [PurchPriceStatus, setPurchPriceStatus] = useState<"Edit" | "Create">(
    "Edit"
  );

  useEffect(() => {
    resetForm();
  }, []);

  const resetForm = () => {
    setShowResults(false);
  };

  const handleChangePurchPriceStatus = (value: "Edit" | "Create") => {
    setPurchPriceStatus(value);
    if (value === "Create")
      setSelectedPurchPriceProfile(NEW_PURCH_PRICE_PROFILE);
    else setSelectedPurchPriceProfile(partner?.purchPriceProfiles[0]);
  };
  const handleChangePartnerType = async ({
    partnerType,
    softwareVendorId,
  }: {
    partnerType: PartnerType;
    softwareVendorId?: string;
  }) => {
    setLoading(true);
    const results = await dispatch(
      editPartnerThunk({
        partnerId: partner?._id,
        //@ts-ignore
        update: { partnerType, softwareVendorId },
      })
    );
    setLoading(false);

    //@ts-ignore
    if (results?.payload?._id) {
      setShowResults(true);
    }
  };

  const handleReset = () => {
    form.resetFields();
  };

  const handleCancel = () => {
    handleReset();
    setVisible(false);
  };

  const editOrCreatePartnerPurchPriceProfile = async (
    purchPriceProfile: IPurchPriceProfile & { pricingTemplate: PricingTemplate }
  ) => {
    let updatedPurchPriceProfiles: IPurchPriceProfile[];
    if (PurchPriceStatus === "Edit") {
      updatedPurchPriceProfiles = partner.purchPriceProfiles.map((pp) => {
        return purchPriceProfile?._id === pp._id ? purchPriceProfile : pp;
      });
    }
    if (PurchPriceStatus === "Create") {
      //@ts-ignore
      delete purchPriceProfile._id;
      updatedPurchPriceProfiles = [
        ...partner.purchPriceProfiles,
        purchPriceProfile,
      ];
    }

    setLoading(true);
    const results = await dispatch(
      editPartnerThunk({
        partnerId: partner?._id,
        //@ts-ignore
        update: { purchPriceProfiles: updatedPurchPriceProfiles },
      })
    );
    setLoading(false);

    //@ts-ignore
    if (results?.payload?._id) {
      setShowResults(true);
    }
  };

  const editOrCreatePartnerDefaultPrice = async (params: {
    purchPriceProfileId: string;
    bucketGroups: PartnerBucketGroups;
  }) => {
    setLoading(true);
    const results = await dispatch(
      editPartnerThunk({
        partnerId: partner?._id,
        //@ts-ignore
        update: { defaultPricing: params.bucketGroups },
      })
    );
    setLoading(false);

    //@ts-ignore
    if (results?.payload?._id) {
      setShowResults(true);
    }

    // handleCancel();
  };
  const editOrCreatePartnerSoftwareVendorPrice = async ({
    bucketGroups,
  }: {
    bucketGroups: PartnerBucketGroups;
  }) => {
    if (!bucketGroups.domesticDebitCards.valueType)
      bucketGroups.domesticDebitCards.valueType = "Fixed";
    if (!bucketGroups.european.valueType)
      bucketGroups.european.valueType = "Percentage";
    if (!bucketGroups.internationalAndCommercial.valueType)
      bucketGroups.internationalAndCommercial.valueType = "Percentage";
    setLoading(true);
    const results = await dispatch(
      editPartnerThunk({
        partnerId: partner?._id,
        //@ts-ignore
        update: { softwareVendorPricing: bucketGroups },
      })
    );
    setLoading(false);

    //@ts-ignore
    if (results?.payload?._id) {
      setShowResults(true);
    }

    // handleCancel();
  };

  function handlePurchPriceProfileSelection(purchPriceProfileId: string) {
    let purchPriceProfile = partner.purchPriceProfiles.find(
      (pp) => pp._id === purchPriceProfileId
    );

    if (purchPriceProfile) setSelectedPurchPriceProfile(purchPriceProfile);
  }
  return (
    <div>
      <Modal
        visible={visible}
        title={`Partner Editor - ${partner.name}`}
        onCancel={handleCancel}
        footer={null}
        width={650}
      >
        <div>
          {!showResults && (
            <Tabs>
              <Tabs.TabPane
                tab="Purchase Price Profiles"
                key="item-1"
                style={{ paddingTop: "10px" }}
              >
                <div>
                  <Segmented
                    options={[
                      {
                        icon: (
                          <span style={{ marginRight: "5px" }}>
                            <EditOutlined></EditOutlined>
                          </span>
                        ),
                        value: "Edit",
                        label: "Edit",
                      },
                      {
                        icon: (
                          <span style={{ marginRight: "5px" }}>
                            <PlusCircleOutlined></PlusCircleOutlined>
                          </span>
                        ),
                        value: "Create",
                        label: "Create",
                      },
                    ]}
                    value={PurchPriceStatus}
                    onChange={(value) => {
                      handleChangePurchPriceStatus(value as "Edit" | "Create");
                    }}
                  />
                  <br />
                  <br />
                  {PurchPriceStatus === "Edit" && (
                    <>
                      <Form.Item label="Please Select The Profile You Wish To Edit ">
                        <Select
                          defaultValue={selectedPurchPriceProfile?._id}
                          onChange={handlePurchPriceProfileSelection}
                          options={partner.purchPriceProfiles?.map((pp) => ({
                            label: pp.name,
                            value: pp._id,
                          }))}
                        ></Select>
                      </Form.Item>
                    </>
                  )}

                  <PurchPriceProfileForm
                    initialProfile={selectedPurchPriceProfile}
                    onSubmit={(pp) => {
                      editOrCreatePartnerPurchPriceProfile(pp);
                    }}
                    hideNameField={
                      selectedPurchPriceProfile?.name === "default"
                        ? true
                        : false
                    }
                    hideWhitelistField={
                      selectedPurchPriceProfile?.name === "default"
                        ? true
                        : false
                    }
                    loading={loading}
                  ></PurchPriceProfileForm>
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane tab="Default Price" key="item-2">
                <CreateEditBucketGroupsForm
                  purchPriceProfiles={partner.purchPriceProfiles!}
                  onSubmit={editOrCreatePartnerDefaultPrice}
                  bucketGroupsToEdit={partner.defaultPricing}
                  purchPriceProfileToEdit={partner.purchPriceProfiles[0]}
                  disableProfileSelection={true}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Software Vendor Price" key="item-3">
                <CreateEditBucketGroupsForm
                  // purchPriceProfiles={partner.purchPriceProfiles!}
                  onSubmit={editOrCreatePartnerSoftwareVendorPrice}
                  bucketGroupsToEdit={partner.softwareVendorPricing}
                  // purchPriceProfileToEdit={partner.purchPriceProfiles[0]}
                  disableProfileSelection={true}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Partner Type" key="item-4">
                <PartnerTypeForm
                  onSubmit={handleChangePartnerType}
                  partnerType={partner.partnerType}
                  softwareVendorId={partner.softwareVendorId}
                  loading={loading}
                />
              </Tabs.TabPane>
            </Tabs>
          )}
          <div className={styles.form_container}>
            {showResults && (
              <>
                The partner was successfully editted.
                <CheckCircleFilled style={{ color: "green" }} />
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
