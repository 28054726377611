import { Form, Modal } from "antd";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { selectCurrentBusiness } from "../../../businesses/businessesSlice";

// import {
//   getAccountingReportFromServer,
//   getPayoutReportFromServer,
//   getSalesOverviewReportFromServer,
//   getTransactionsOverviewReportFromServer,
// } from "../../reportsApi";
import {
  IPartnerReportRequest,
  IReportRequest,
} from "../../../../interfaces/requestReport.interface";

import moment from "moment";
import {
  IPartnerReport,
  IReportResponse,
} from "../../../../interfaces/reports.interface";
import CreateReportForm from "../../../common/create-report-form/CreateReportForm";
import { selectCurrentPartner } from "../../partnersSlice";
import { getPartnerMarkupReportFromServer } from "../../partnersApi";

interface IPropsCreateReportFormModal {
  visible: boolean;
  setVisible: any;
}

let ReportOptions: IPartnerReport[] = [
  {
    type: "resellerMarkupReport",
    label: "Reseller Markup Report",
    formatTypes: ["XLSX"],
    previewUrl: "/assets/icons/report/sales-overview.svg",
    description:
      "Get the reseller parner mark up for the selected time period.",
  },
  {
    type: "softwareVendorMarkupReport",
    label: "ISV Markup Report",
    formatTypes: ["XLSX"],
    previewUrl: "/assets/icons/report/transactions-overview.svg",
    description:
      "Get the software vendor parner mark up for the selected time period.",
  },
];

export default function CreatePartnerReportFormModal({
  visible,
  setVisible,
}: IPropsCreateReportFormModal) {
  const currentPartner = useAppSelector(selectCurrentPartner);
  const [report, setReport] = useState<IPartnerReport>(ReportOptions[0]);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      dateRange: [
        moment().set({
          date: moment().date() - 1,
          minute: 0,
          hour: 0,
        }),
        moment().set({
          minute: 59,
          hour: 23,
        }),
      ],
    });
  }, [report.type]);

  const handleSubmit = async (
    reportRequest: IReportRequest,
    reportType?: string
  ) => {
    let partnerReportRequest = {
      ...reportRequest,
      partnerId: currentPartner._id,
      reportType:
        reportType === "resellerMarkupReport" ? "reseller" : "softwareVendor",
    } as IPartnerReportRequest;

    let results = {} as IReportResponse;
    // if (reportType === "resellerMarkupReport") {
    await getPartnerMarkupReportFromServer(partnerReportRequest)
      .then((d) => (results = d))
      .catch((e) => alert(`Error: ${e}`));

    return results;
    // }else{

    // }
  };
  const handleCancel = () => {
    // handleReset();
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      title={"Create Report For " + currentPartner?.name}
      onCancel={handleCancel}
      footer={null}
      width={650}
    >
      <CreateReportForm
        onSubmit={handleSubmit}
        reportOptions={ReportOptions}
      ></CreateReportForm>
    </Modal>
  );
}
