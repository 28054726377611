import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";

import {
  getPartnersFromServer,
  createPartnerServer,
  editPartnerServer,
  createPartnerTeamMemberServer,
  getPartnerMarkupReportFromServer,
} from "./partnersApi";
import {
  IPartner,
  IPartnerTeamMember,
  PartnerType,
} from "../../interfaces/partner.interface";
import { IPartnerDeal } from "../../interfaces/partnerDeal.interface";
import {
  IPartnerReportRequest,
  IReportRequest,
} from "../../interfaces/requestReport.interface";
import { PartnerReportType } from "../../interfaces/reports.interface";

// import { fetchCount } from './counterAPI';

export interface PartnerState {
  partners: IPartner[];
  currentPartner: IPartner;
}

const initialState: PartnerState = {
  partners: [] as IPartner[],
  currentPartner: {} as IPartner,
};

export const getPartnersThunk = createAsyncThunk(
  "partners/getAll",
  async (params?: Partial<IPartner> & { partnerNames?: string }) => {
    const response = await getPartnersFromServer(params);

    return response;
  }
);
export const getPartnerThunk = createAsyncThunk(
  "partners/get",
  async (partnerId: string) => {
    const response = await getPartnersFromServer({ partnerId });

    return response?.partners?.length > 0 ? response?.partners[0] : null;
  }
);

export const createPartnerThunk = createAsyncThunk(
  "partners/create",
  async (partner: Partial<IPartner>) => {
    const response = await createPartnerServer(partner);
    return response;
  }
);
export const createPartnerTeamMemberThunk = createAsyncThunk(
  "partners/teamMembers/create",
  async (params: {
    teamMember: Partial<IPartnerTeamMember>;
    partnerId: string;
  }) => {
    const response = await createPartnerTeamMemberServer(params);
    return response;
  }
);
export const editPartnerThunk = createAsyncThunk(
  "partners/edit",
  async (params: { partnerId: string; update: Partial<IPartner> }) => {
    const response = await editPartnerServer(params);
    return response;
  }
);
export const getPartnerMarkupReportThunk = createAsyncThunk(
  "partners/markup",
  async (reportRequest: IPartnerReportRequest) => {
    const response = await getPartnerMarkupReportFromServer(reportRequest);
    return response;
  }
);

export const partnersSlice = createSlice({
  name: "partners",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCurrentPartner: (state, action: PayloadAction<string>) => {
      let partner = state.partners.find((p) => p._id === action.payload);
      if (partner) state.currentPartner = partner;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder

      .addCase(getPartnersThunk.fulfilled, (state, action) => {
        state.partners = action.payload.partners;
      })
      .addCase(getPartnerThunk.fulfilled, (state, action) => {
        if (action.payload) state.currentPartner = action.payload;
      })
      .addCase(createPartnerThunk.fulfilled, (state, action) => {
        state.partners.push(action.payload);
      })

      .addCase(createPartnerTeamMemberThunk.fulfilled, (state, action) => {
        state.partners = state.partners.map((p) =>
          p._id === action.payload._id ? action.payload : p
        );

        state.currentPartner = action.payload;
      })
      .addCase(editPartnerThunk.fulfilled, (state, action) => {
        state.partners = state.partners.map((p) =>
          p._id === action.payload._id ? action.payload : p
        );
      });
  },
});

export const { setCurrentPartner } = partnersSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectPartners = (state: RootState) => state.partners.partners;
export const selectSoftwareVendors = (state: RootState) =>
  state.partners.partners.filter(
    (p) =>
      p.partnerType === PartnerType.SoftwareVendor ||
      p.partnerType === PartnerType.FullServiceWithResellers
  );
export const selectCurrentPartner = (state: RootState) =>
  state.partners.currentPartner;

export default partnersSlice.reducer;
