import {
  Badge,
  Button,
  Col,
  Descriptions,
  Divider,
  List,
  Modal,
  Popconfirm,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { IBusinessFull } from "../../../../../interfaces/businessFull.interface";
import {
  UserOutlined,
  FileOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import Loader from "../../../../common/loader/Loader";
import {
  deleteAndArchiveBusinessThunk,
  revertSubmissionBusinessThunk,
  selectCurrentBusiness,
} from "../../../businessesSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { downloadFile } from "../../../../../utils/downloadFile";
const { Paragraph } = Typography;

interface IPropsBusinessSensitiveActions {
  business: Partial<IBusinessFull>;
  setVisible: (boolean: boolean) => void;
}

export default function BusinessSensitiveActions({
  business,
  setVisible,
}: IPropsBusinessSensitiveActions) {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleDeleteAndArchive = async () => {
    if (!business?._id) return;

    try {
      setIsLoading(true);
      await dispatch(deleteAndArchiveBusinessThunk({ _id: business._id }));
      setSuccessMessage("Business deleted and archived successfully");
      setTimeout(() => {
        setSuccessMessage("");
        setVisible(false);
      }, 2000);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRevertSubmission = async () => {
    if (!business?._id) return;
    try {
      setIsLoading(true);
      await dispatch(revertSubmissionBusinessThunk({ _id: business._id }));
      setSuccessMessage("Business reverted to pending submission successfully");
      setTimeout(() => {
        setSuccessMessage("");
        setVisible(false);
      }, 2000);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ position: "relative", minHeight: "100px" }}>
      {!isLoading && (
        <>
          {(business?.status === "pendingSubmission" ||
            business?.status === "pendingVerification") &&
            !successMessage && (
              <>
                <Divider />

                <Typography.Title level={5}>Delete Business</Typography.Title>
                <Typography.Text
                  style={{ display: "block", marginBottom: "10px" }}
                >
                  Will delete business and archive it in the business graveyard
                  for 30 days.
                </Typography.Text>

                <Popconfirm
                  title="Delete this business?"
                  onConfirm={handleDeleteAndArchive}
                  okButtonProps={{ danger: true }}
                  children={
                    <Button
                      type="primary"
                      danger
                      style={{ marginBottom: "40px" }}
                    >
                      Delete
                    </Button>
                  }
                ></Popconfirm>
              </>
            )}
          {business?.status === "pendingVerification" && !successMessage && (
            <>
              <Divider />

              <Typography.Title level={5}>
                Revert to Pending Submission
              </Typography.Title>
              <Typography.Text
                style={{ display: "block", marginBottom: "10px" }}
              >
                Will revert the business to pending submission status. This will
                allow the business to be edited and resubmitted.
              </Typography.Text>
              <Popconfirm
                title="Revert to pending submission?"
                onConfirm={handleRevertSubmission}
                okButtonProps={{ danger: true }}
              >
                <Button type="primary" style={{ marginBottom: "10px" }}>
                  Revert
                </Button>
              </Popconfirm>
            </>
          )}
        </>
      )}
      {!(
        business?.status === "pendingSubmission" ||
        business?.status === "pendingVerification"
      ) && <>No actions available for this business</>}

      {successMessage?.length > 0 && (
        <Typography.Text
          style={{
            display: "block",
            marginBottom: "10px",
            marginTop: "30px",
            color: "green",
            fontSize: "16px",
          }}
        >
          <CheckCircleOutlined style={{ color: "green", marginRight: "3px" }} />
          {successMessage}
        </Typography.Text>
      )}

      {isLoading && <Loader text="Please wait" />}
    </div>
  );
}
