import {
  createAsyncThunk,
  createSlice,
  current,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import {
  BusinessStatus,
  BusinessType,
  IBusinessFull,
  IBusinessFullQuery,
} from "../../interfaces/businessFull.interface";
import { IBusinessSimple } from "../../interfaces/businessSimple.interface";
import { IBusinessUser } from "../../interfaces/businessUser.interface";
import { IQueryFilters } from "../../interfaces/queryFilters.interface";
import { getUniqueKey } from "../../utils/getUniqueKey";
import {
  approveBusinessServer,
  completeRegistrationServer,
  declineBusinessServer,
  deleteAndArchiveBusinessServer,
  editBusinessServer,
  editBusinessUsersEmailServer,
  editBusinessUserServer,
  getBusinessAdditionalFilesFromServer,
  getBusinessDetailsFromServer,
  getBusinessesFromServer,
  getBusinessUsersFromServer,
  markBusinessAsPendingVerificationServer,
  revertSubmissionBusinessServer,
} from "./businessesApi";
import { IBusinessFile } from "../../interfaces/businessFile.interface";

// import { fetchCount } from './counterAPI';

export interface BusinessesState {
  businesses: IBusinessFull[];
  totalCount: number;
  businessesInTable: IBusinessFull[];
  currentBusiness: IBusinessFull;
  currentBusinessFiles: IBusinessFile[];
  currentBusinessUsers: IBusinessUser[];
  currentBusinessUser: IBusinessUser;
  businessTableStatus: "all" | BusinessStatus;
  businessTableType: "all" | BusinessType;
  lastUpdateKey: string;
  filters: Partial<IBusinessFullQuery>;
  isLoadingBusinessFiles: boolean;
  isLoadingBusinessUsers: boolean;
  isLoadingBusinesses: boolean;
}

const initialState: BusinessesState = {
  businesses: [],
  totalCount: 0,
  businessesInTable: [],
  businessTableStatus: "all",
  businessTableType: "all",
  currentBusiness: {} as any,
  currentBusinessFiles: [] as any,
  currentBusinessUsers: [] as any,
  currentBusinessUser: {} as any,
  lastUpdateKey: "",
  filters: {},
  isLoadingBusinessFiles: false,
  isLoadingBusinessUsers: false,
  isLoadingBusinesses: false,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(getBusinesses(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getBusinessesThunk = createAsyncThunk(
  "businesses/getAll",
  async (params: IQueryFilters<IBusinessFullQuery>) => {
    const { data, totalCount } = await getBusinessesFromServer(params);
    return { data, totalCount };
  }
);

export const getBusinessThunk = createAsyncThunk(
  "businesses/getBusiness",
  async (businessId: string) => {
    const response = await getBusinessDetailsFromServer(businessId);

    return response;
  }
);
export const getBusinessFilesThunk = createAsyncThunk(
  "businesses/files",
  async (params: {
    email: string;
    businessRegistrationNumber: string;
    businessName: string;
    iban: string;
    name: string;
  }) => {
    const response = await getBusinessAdditionalFilesFromServer(params);
    return response;
  }
);
export const getBusinessUsersThunk = createAsyncThunk(
  "businesses/getBusinessUsers",
  async (businessId: string) => {
    const response = await getBusinessUsersFromServer(businessId);
    return response.users;
  }
);

export const completeRegistrationThunk = createAsyncThunk(
  "businesses/completeRegistration",
  async (props: { businessId: string }) => {
    const response = await completeRegistrationServer(props);
    return response;
  }
);
export const deleteAndArchiveBusinessThunk = createAsyncThunk(
  "businesses/deleteAndArchive",
  async (props: { _id: string }) => {
    const response = await deleteAndArchiveBusinessServer(props);
    return { _id: props._id };
  }
);
export const revertSubmissionBusinessThunk = createAsyncThunk(
  "businesses/revertSubmission",
  async (props: { _id: string }) => {
    const response = await revertSubmissionBusinessServer(props);
    return response;
  }
);
export const approveBusinessThunk = createAsyncThunk(
  "businesses/approve",
  async (props: { _id: string; email: string }) => {
    const response = await approveBusinessServer(props);
    return response;
  }
);

export const declineBusinessThunk = createAsyncThunk(
  "businesses/decline",
  async (props: { _id: string; email: string }) => {
    const response = await declineBusinessServer(props);
    return response;
  }
);
export const markAsPendingVerificationThunk = createAsyncThunk(
  "businesses/updateStatus",
  async (props: { _id: string }) => {
    const response = await markBusinessAsPendingVerificationServer(props);
    return response;
  }
);

export const editBusinessThunk = createAsyncThunk(
  "businesses/edit",
  async (props: { _id: string; updatedFields: Partial<IBusinessFull> }) => {
    const response = await editBusinessServer(props);

    return response;
  }
);
export const editBusinessUserThunk = createAsyncThunk(
  "businesses/editUser",
  async (props: { _id: string; updatedFields: Partial<IBusinessUser> }) => {
    const response = await editBusinessUserServer(props);
    return response;
  }
);
export const editBusinessUsersEmailThunk = createAsyncThunk(
  "businesses/editUserEmail",
  async (props: { _id: string; newEmail: string; oldEmail: string }) => {
    const response = await editBusinessUsersEmailServer(props);
    return response;
  }
);
//utils//
const applyFiltersToBusinesses = (
  businesses: IBusinessFull[],
  filters: {
    [key: string]: ((businesses: IBusinessFull[]) => IBusinessFull[]) | null;
  }
) => {
  const filterArr = Object.values(filters);
  const filteredBusinesses = filterArr.reduce((prev, cur) => {
    if (prev.length === 0) return [];
    if (cur) return cur(prev);
    else return prev;
  }, businesses);

  return filteredBusinesses;
};

export const businessesSlice = createSlice({
  name: "businesses",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // filterTableByStatus: (
    //   state,
    //   action: PayloadAction<"all" | BusinessStatus>
    // ) => {
    //   const status = action.payload;
    //   const statusFilter = (businesses: IBusinessFull[]) => {
    //     return businesses.filter((b) => b.status === status);
    //   };

    //   state.filters.status = status === "all" ? null : statusFilter;
    //   state.businessesInTable = applyFiltersToBusinesses(
    //     state.businesses,
    //     state.filters
    //   );
    // },
    // filterTableByType: (state, action: PayloadAction<"all" | BusinessType>) => {
    //   const businessType = action.payload;

    //   const typeFilter = (businesses: IBusinessFull[]) => {
    //     return businesses.filter((b) => b.businessType === businessType);
    //   };

    //   state.filters.type = businessType === "all" ? null : typeFilter;
    //   state.businessesInTable = applyFiltersToBusinesses(
    //     state.businesses,
    //     state.filters
    //   );
    // },

    // filterTableByName: (state, action: PayloadAction<string>) => {
    //   const name = action.payload;

    //   const nameFilter = (businesses: IBusinessFull[]) => {
    //     return businesses.filter(
    //       (b) =>
    //         b.businessName.toLowerCase().includes(name) ||
    //         b.rootUser?.email.toLowerCase().includes(name) ||
    //         b.rootUser?.name.toLowerCase().includes(name) ||
    //         b.businessRegistrationNumber.startsWith(name)
    //     );
    //   };

    //   state.filters.name = name === "" ? null : nameFilter;
    //   state.businessesInTable = applyFiltersToBusinesses(
    //     state.businesses,
    //     state.filters
    //   );
    // },
    setCurrentBusiness: (state, action: PayloadAction<IBusinessFull>) => {
      state.currentBusiness = action.payload;
    },
    setCurrentBusinessUser: (state, action: PayloadAction<IBusinessUser>) => {
      state.currentBusinessUser = action.payload;
    },
    setFilters: (state, action: PayloadAction<Partial<IBusinessFullQuery>>) => {
      let filters = { ...state.filters, ...action.payload };
      for (const key in filters) {
        //@ts-ignore
        if (!filters[key]) {
          //@ts-ignore
          delete filters[key];
        }
      }
      state.filters = filters;
    },
    resetFilters: (state) => {
      let filters = { ...state.filters };
      for (const key in filters) {
        //@ts-ignore
        if (filters[key]) {
          //@ts-ignore
          delete filters[key];
        }
      }
      state.filters = filters;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getBusinessesThunk.fulfilled, (state, action) => {
        state.businesses = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.isLoadingBusinesses = false;
      })
      .addCase(getBusinessesThunk.pending, (state, action) => {
        state.isLoadingBusinesses = true;
      })
      .addCase(getBusinessesThunk.rejected, (state, action) => {
        state.isLoadingBusinesses = false;
      })
      .addCase(getBusinessThunk.fulfilled, (state, action) => {
        state.currentBusiness = action.payload;
      })
      .addCase(getBusinessUsersThunk.pending, (state, action) => {
        state.isLoadingBusinessUsers = true;
      })
      .addCase(getBusinessUsersThunk.fulfilled, (state, action) => {
        state.currentBusinessUsers = action.payload;
        state.isLoadingBusinessUsers = false;
      })
      .addCase(getBusinessUsersThunk.rejected, (state, action) => {
        state.isLoadingBusinessUsers = false;
      })
      .addCase(getBusinessFilesThunk.pending, (state, action) => {
        state.isLoadingBusinessFiles = true;
      })
      .addCase(getBusinessFilesThunk.fulfilled, (state, action) => {
        state.currentBusinessFiles = action.payload;
        state.isLoadingBusinessFiles = false;
      })
      .addCase(getBusinessFilesThunk.rejected, (state, action) => {
        state.isLoadingBusinessFiles = false;
      })
      // .addCase(editBusinessThunk.fulfilled, (state, action) => {
      //   state.currentBusiness = action.payload;

      //   state.businesses = state.businesses.map((b) =>
      //     b._id === action.payload._id ? action.payload : b
      //   );
      //   state.businessesInTable = state.businesses.map((b) =>
      //     b._id === action.payload._id ? action.payload : b
      //   );
      // })
      .addCase(editBusinessUserThunk.fulfilled, (state, action) => {
        state.currentBusinessUsers = state.currentBusinessUsers.map((u) =>
          u._id == action.payload._id ? action.payload : u
        );
        state.lastUpdateKey = getUniqueKey();
      })
      .addCase(deleteAndArchiveBusinessThunk.fulfilled, (state, action) => {
        const businesses = state.businesses.filter(
          (b) => b._id !== action.payload._id
        );
        state.businesses = businesses;
        state.lastUpdateKey = getUniqueKey();
      })
      .addCase(editBusinessUsersEmailThunk.fulfilled, (state, action) => {
        state.currentBusinessUsers = state.currentBusinessUsers.map((u) =>
          u._id == action.payload._id ? action.payload : u
        );
        state.lastUpdateKey = getUniqueKey();
      })
      .addMatcher(
        isAnyOf(
          approveBusinessThunk.fulfilled,
          declineBusinessThunk.fulfilled,
          revertSubmissionBusinessThunk.fulfilled,
          editBusinessThunk.fulfilled,
          completeRegistrationThunk.fulfilled,
          markAsPendingVerificationThunk.fulfilled
        ),
        (state, action) => {
          const businesses = state.businesses.map((b) =>
            b._id === action.payload._id ? action.payload : b
          );
          state.businesses = businesses;
          state.currentBusiness = action.payload;
          // state.businessesInTable = applyFiltersToBusinesses(
          //   state.businesses,
          //   state.filters
          // );
          state.lastUpdateKey = getUniqueKey();
        }
      );
  },
});

// export const { getBusinesses } = businessesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectBusinesses = (state: RootState) =>
  state.businesses.businesses;
export const selectTotalBusinessCount = (state: RootState) =>
  state.businesses.totalCount;
export const selectBusinessesInTable = (state: RootState) =>
  state.businesses.businessesInTable;
export const selectIsLoadingBusinesses = (state: RootState) =>
  state.businesses.isLoadingBusinesses;
export const selectCurrentBusiness = (state: RootState) =>
  state.businesses.currentBusiness;
export const selectCurrentBusinessFiles = (state: RootState) =>
  state.businesses.currentBusinessFiles;
export const selectIsLoadingBusinessFiles = (state: RootState) =>
  state.businesses.isLoadingBusinessFiles;
export const selectIsLoadingBusinessUsers = (state: RootState) =>
  state.businesses.isLoadingBusinessUsers;
export const selectCurrentBusinessUsers = (state: RootState) =>
  state.businesses.currentBusinessUsers;
export const selectCurrentBusinessUser = (state: RootState) =>
  state.businesses.currentBusinessUser;
export const selectLastUpdateKey = (state: RootState) =>
  state.businesses.lastUpdateKey;
export const selectFilters = (state: RootState) => state.businesses.filters;
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export const {
  setCurrentBusiness,
  setCurrentBusinessUser,
  setFilters,
  resetFilters,
} = businessesSlice.actions;

export default businessesSlice.reducer;
