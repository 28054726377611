// import axios from "axios";
import { store } from "../../app/store";
import { CONFIG } from "../../config";
import { IBusinessFile } from "../../interfaces/businessFile.interface";
import {
  IBusinessFull,
  IBusinessFullQuery,
} from "../../interfaces/businessFull.interface";
import { IBusinessUser } from "../../interfaces/businessUser.interface";
import { IQueryFilters } from "../../interfaces/queryFilters.interface";
import { ISurepayIbanCheckCache } from "../../interfaces/surepayCheckCache.interface";
import { ISurepayIbanCheckResponse } from "../../interfaces/surepayIbanCheckResponse.interface";
import { getTokenizedAxios } from "../../utils/axiosWithTokenGen";

export async function getBusinessesFromServer(
  params: IQueryFilters<IBusinessFullQuery>
) {
  const axios = await getTokenizedAxios();
  const path = "/businesses/getAll";
  return axios
    .get(CONFIG.ADMIN_SERVER.BASE_URL + path, { params })
    .then((data) => data.data);
}
export async function getBusinessUsersFromServer(_id: string) {
  const axios = await getTokenizedAxios();
  const path = "/businesses/getBusinessUsers/";
  return axios
    .get(CONFIG.ADMIN_SERVER.BASE_URL + path + _id)
    .then((data) => data.data);
}

export async function getBusinessDetailsFromServer(_id: string) {
  const axios = await getTokenizedAxios();
  const path = "/businesses/getBusinessDetails/";

  return axios
    .get(CONFIG.SERVER.BASE_URL + path + _id)
    .then((data) => data.data);
}
export async function getIbanCheckResultsFromServer(
  iban: string,
  email: string
) {
  const axios = await getTokenizedAxios();
  const path = "/businesses/ibanCheck/results";
  return axios
    .get<ISurepayIbanCheckCache>(CONFIG.ADMIN_SERVER.BASE_URL + path, {
      params: { iban, email },
    })
    .then((data) => data.data);
}

export interface IIbanCheckServerParams {
  IBAN: string;
  email: string;
  name?: string;
  companyId?: string;
}
export async function checkIbanServer(params: {
  IBAN: string;
  email: string;
  name?: string;
  companyId?: string;
}) {
  const axios = await getTokenizedAxios();
  const path = "/businesses/checkIban";
  return axios
    .post<ISurepayIbanCheckResponse>(CONFIG.SERVER.BASE_URL + path, params)
    .then((data) => data.data);
}

export async function getBusinessAdditionalFilesFromServer(params: {
  email: string;
  businessRegistrationNumber: string;
  businessName: string;
  iban: string;
  name: string;
}): Promise<IBusinessFile[]> {
  const axios = await getTokenizedAxios();
  const path = "/businesses/files";
  return axios
    .get(CONFIG.ADMIN_SERVER.BASE_URL + path, { params })
    .then((data) => data.data);
}
export async function getImageFromServer({
  fileName,
  bucketName,
}: {
  fileName: string;
  bucketName: string;
}) {
  const axios = await getTokenizedAxios();
  const path = "/images/";

  return axios
    .get(CONFIG.ADMIN_SERVER.BASE_URL + path, {
      params: { fileName, bucketName },
    })
    .then((data) => data.data);
}

export async function extractDeviceLogs({
  deviceId,
  businessId,
  start,
  end,
  fileFormat,
}: {
  deviceId: string;
  businessId: string;
  start: string;
  end: string;
  fileFormat: "zip" | "txt";
}): Promise<{ url: string }> {
  const axios = await getTokenizedAxios();
  const path = "/businesses/devices/logs/extract";

  return axios
    .post(CONFIG.ADMIN_SERVER.BASE_URL + path, {
      deviceId,
      businessId,
      fileFormat,
      start,
      end,
    })
    .then((data) => data.data);
}

export async function getUploadUrlFromServer({
  fileName,
  bucketName,
}: {
  fileName: string;
  bucketName: string;
}): Promise<string> {
  const axios = await getTokenizedAxios();
  const path = "/images/upload/url";

  return axios
    .get(CONFIG.ADMIN_SERVER.BASE_URL + path, {
      params: { fileName, bucketName },
    })
    .then((data) => data.data);
}

export async function uploadImageServer({
  fileName,
  bucketName,
  image,
}: {
  fileName?: string;
  bucketName?: string;
  image: string | Buffer | ArrayBuffer;
}) {
  const axios = await getTokenizedAxios();
  const path = "/images/upload/";
  return axios
    .post(CONFIG.ADMIN_SERVER.BASE_URL + path, {
      fileName,
      bucketName,
      image,
    })
    .then((data) => data.data);
}
export async function completeRegistrationServer(params: {
  businessId: string;
}) {
  const { businessId } = params;
  const axios = await getTokenizedAxios();
  const path = "/businesses/completeRegistration/";

  return axios
    .post<IBusinessFull>(CONFIG.ADMIN_SERVER.BASE_URL + path, {
      businessId,
    })
    .then((data) => data.data);
}
export async function editBusinessServer(params: {
  _id: string;
  updatedFields: Partial<IBusinessFull>;
}) {
  const { _id, updatedFields } = params;
  const axios = await getTokenizedAxios();
  const path = "/businesses/editBusiness/";

  return axios
    .put<IBusinessFull>(CONFIG.ADMIN_SERVER.BASE_URL + path + _id, {
      updatedFields,
    })
    .then((data) => data.data);
}
export async function editBusinessUserServer(params: {
  _id: string;
  updatedFields: Partial<IBusinessUser>;
}) {
  const { _id, updatedFields } = params;
  const axios = await getTokenizedAxios();
  const path = "/users/edit/";

  return axios
    .put<IBusinessUser>(CONFIG.ADMIN_SERVER.BASE_URL + path + _id, {
      updatedFields,
    })
    .then((data) => data.data);
}
export async function editBusinessUsersEmailServer(params: {
  _id: string;
  oldEmail: string;
  newEmail: string;
}) {
  const { _id, oldEmail, newEmail } = params;
  const axios = await getTokenizedAxios();
  const path = "/users/edit/email/";
  return axios
    .put<IBusinessUser>(CONFIG.ADMIN_SERVER.BASE_URL + path + _id, {
      oldEmail,
      newEmail,
    })
    .then((data) => data.data);
}

export async function deleteAndArchiveBusinessServer(props: { _id: string }) {
  const { _id } = props;
  const axios = await getTokenizedAxios();
  const path = "/businesses/deleteAndArchive/" + _id;
  return axios
    .delete(CONFIG.ADMIN_SERVER.BASE_URL + path)
    .then((data) => data.data);
}
export async function revertSubmissionBusinessServer(props: { _id: string }) {
  const { _id } = props;
  const axios = await getTokenizedAxios();
  const path = "/businesses/updateBusinessStatus";
  return axios
    .patch(CONFIG.ADMIN_SERVER.BASE_URL + path, {
      _id,
      email: "-",
      status: "pendingSubmission",
    })
    .then((data) => data.data);
}
export async function approveBusinessServer(props: {
  _id: string;
  email: string;
}) {
  const { _id, email } = props;
  const axios = await getTokenizedAxios();
  const path = "/businesses/updateBusinessStatus";
  return axios
    .patch(CONFIG.ADMIN_SERVER.BASE_URL + path, {
      _id,
      email,
      status: "verified",
    })
    .then((data) => data.data);
}
export async function declineBusinessServer(props: {
  _id: string;
  email: string;
}) {
  const { _id, email } = props;
  const axios = await getTokenizedAxios();
  const path = "/businesses/updateBusinessStatus";
  return axios
    .patch(CONFIG.ADMIN_SERVER.BASE_URL + path, {
      _id,
      email,
      status: "unverified",
    })
    .then((data) => data.data);
}

export async function markBusinessAsPendingVerificationServer(props: {
  _id: string;
}) {
  const { _id } = props;
  const axios = await getTokenizedAxios();
  const path = "/businesses/updateBusinessStatus";
  return axios
    .patch(CONFIG.ADMIN_SERVER.BASE_URL + path, {
      _id,
      status: "pendingVerification",
    })
    .then((data) => data.data);
}
export async function getBusinessesContactListServer() {
  const axios = await getTokenizedAxios();
  const path = "/businesses/contactList";
  return axios
    .get(CONFIG.ADMIN_SERVER.BASE_URL + path)
    .then((data) => data.data);
}
